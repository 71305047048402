<template>
  <div class="nottimes-container">
    <div class="nottimes-img">
      <img src="/assets/error/nottimes.png" alt="" />
    </div>
    <div class="nottimes-info">检测次数已用完</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
